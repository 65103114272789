import React, {useEffect, useState} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Button} from 'flowbite-react';

import {ErrorHandling} from '../../components/ErrorHandling';
import {SelectSubject} from './SelectSubject';
import {SelectTeacher} from './SelectTeacher';
import {SelectClassRoom} from './SelectClassRoom';
import Loader from '../../components/Loader';

const CREATE_SCHEDULE = gql`
    mutation createSchedule(
        $classroom: ID,
        $day: ENUM_SCHEDULE_DAY,
        $subject: ID,
        $teacher: ID
        $schedule_time: ID
        $schedule_name: ID
    ) {
        createSchedule(
            data: {classroom: $classroom, day: $day, subject: $subject, teacher: $teacher, schedule_time: $schedule_time, schedule_name: $schedule_name }
        ) {
            data {
                attributes {
                    day
                    teacher{
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                    classroom{
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                    subject{
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

const SCHEDULE_LIST = gql`
    query schedules($pagination: PaginationArg, $filters: ScheduleFiltersInput) {
        schedules(pagination: $pagination, filters: $filters) {
            data {
                attributes {
                    subject {
                        data {
                            attributes{
                                name
                            }
                        }
                    }
                    day
                    schedule_time{
                        data {
                            attributes {
                                timeStart
                                timeEnd
                            }
                            id
                        }
                    }
                    schedule_name {
                        data {
                            id
                        }
                    }
                    classroom{
                        data {
                            attributes{
                                name
                            }
                        }
                    }
                    teacher {
                        data {
                            attributes{
                                name
                            }
                        }
                    }
                }
                id
            }
        }
    }
`;

const dayMap = {
  'Понедельник': 'Monday',
  'Вторник': 'Tuesday',
  'Среда': 'Wednesday',
  'Четверг': 'Thursday',
  'Пятница': 'Friday',
  'Суббота': 'Saturday',
};

export const InputLesson = ({day, scheduleName, timeObj, onAdd, onClose, onCancel}) => {
  const translateDayToEnglish = (dayInRussian) => dayMap[dayInRussian] || dayInRussian;
  const [createSchedule, {loading}] = useMutation(CREATE_SCHEDULE);

  const [classroomError, setClassroomError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [teacherError, setTeacherError] = useState('');

  const {data: scheduleByTimeAndDayData, error: errorSchedules, loading: loadingScheduleByTimeAndDayData} = useQuery(SCHEDULE_LIST, {
    variables: {
      pagination: {
        limit: 101,
      },
      filters: {
        institution: {id: {eq: localStorage.getItem('schoolID')}},
      },
    },
    skip: localStorage.getItem('schoolID') === '' || !timeObj || !timeObj?.id || !day,
  });

  const [lesson, setLesson] = useState({
    schedule_time: undefined,
    subject: '',
    classroom: undefined,
    teacher: undefined,
    classroomId: '',
    teacherId: '',
  });
  const [buttonText, setButtonText] = useState('Добавить');
  const [isDisabled, setIsDisabled] = useState(false);

  const existingSchedule = scheduleByTimeAndDayData && scheduleByTimeAndDayData.schedules.data.length > 0
    ? scheduleByTimeAndDayData.schedules.data[0]
    : null;

  useEffect(() => {
    if (existingSchedule) {
      const scheduleData = existingSchedule.attributes;
      setLesson(prev => ({
        ...prev,
        teacher: scheduleData?.teacher?.data?.id,
        teacherId: scheduleData?.teacher?.data?.id,
        subject: scheduleData?.subject?.data?.id,
        classroom: scheduleData?.classroom?.data?.id,
        classroomId: scheduleData?.classroom?.data?.id,
      }));
    }
  }, [existingSchedule]);

  
  const handleInputChanges = (values) => {
    resetErrors();
    setLesson(prev => ({...prev, ...values}));
    setIsDisabled(false);
    setButtonText('Добавить');
  };

  const resetErrors = () => {
    setClassroomError('');
    setSubjectError('');
    setTeacherError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    resetErrors();
    if (lesson.subject === '') {
      setSubjectError('Обязательное поле');
      return;
    }

    if (lesson.teacher === '') {
      setTeacherError('Обязательное поле');
      return;
    }

    if (lesson.classroom === '') {
      setClassroomError('Обязательное поле');
      return;
    }

    const scheduleExists = !!existingSchedule;
    const dayInEnglish = translateDayToEnglish(day);

    try {
      let response;
      response = await createSchedule({
        variables: {
          ...lesson,
          day: dayInEnglish,
          schedule_time: timeObj.id,
          schedule_name: scheduleName,
          ...(scheduleExists && {id: existingSchedule.id}),
        },
        refetchQueries: ['schedules', 'scheduleTimes', 'scheduleNames'],
      });

      if (onAdd && typeof onAdd === 'function') {
        onAdd(response.data);
      }
      onClose();
      setButtonText(scheduleExists ? 'Обновлено' : 'Добавлено');
      setIsDisabled(true);
      // Если нужно очистить форму после отправки, раскомментируйте следующую строку
      // setLesson({ schedule_time: undefined, subject: '', classroom: undefined, teacher: undefined });
    } catch (e) {
      alert('Ошибка при обновлении/добавлении расписания:' + e.toString());
      console.error('Ошибка при обновлении/добавлении расписания', e);
    }
  };

  return (
    <div className='form flex flex-col gap-4 mb-4'>
      {loadingScheduleByTimeAndDayData && <Loader/>}
      <ErrorHandling error={[errorSchedules]}/>

      <SelectSubject
        hasError={subjectError}
        withClassRoom={true}
        withTeacher={true}

        onChange={(selectedKey) => {
          console.log('SelectSubject', selectedKey);
          handleInputChanges({
            'teacher': selectedKey.teacherId ?? '',
            'subject': selectedKey.subjectId ?? '',
            'classroom': selectedKey.classRoomId ?? '',
          });
        }}
      />

      <SelectTeacher
        hasError={teacherError}
        value={lesson.teacher || ''}
        onChange={(value) => {
          handleInputChanges({'teacher': value});
        }}
      />

      <SelectClassRoom
        hasError={classroomError}
        value={lesson.classroom || ''}
        onChange={(value) => {
          handleInputChanges({'classroom': value});
        }}
      />

      <Button color='gray' className='schedule-btn' onClick={onCancel} type='submit'>Отмена</Button>
      <Button className='schedule-btn' disabled={isDisabled} isProcessing={loading} onClick={handleSubmit}
        type='submit'>{buttonText}</Button>
    </div>
  );
};
