import {Select} from 'flowbite-react';
import React, {useEffect, useState} from 'react';
import {useQuery, gql} from '@apollo/client';
import {ErrorHandling} from '../../components/ErrorHandling';
import Loader from '../../components/Loader';

const TEACHERS_LIST = gql`
    query teachers($filters: TeacherFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        teachers(filters: $filters, pagination: $pagination, sort: $sort)  {
            data {
                attributes{
                    name
                }
                id
            }
        }
    }
`;

export const SelectTeacher = ({
  hasError,
  value,
  onChange,
}) => {
  const [teacherList, setTeacherList] = useState([]);

  const {data: teachersResult, error: errorTeacher, loading} = useQuery(TEACHERS_LIST, {
    variables: {
      filters: {
        institution: {id: {eq: localStorage.getItem('schoolID')}},
      },
      pagination: {
        limit: 500,
      },
      sort: ['name'],
    },
    skip: localStorage.getItem('schoolID') === '',
  });

  useEffect(() => {
    if (!teachersResult?.teachers?.data) {
      return;
    }

    const res = [];

    teachersResult.teachers.data.forEach((item) => {
      const name = item.attributes?.name;
      res.push({
        id: item.id,
        name: name.trim(),
      }
      );
    });

    setTeacherList(res.sort((a, b) => a.name.localeCompare(b.name)));
  }, [teachersResult]);

  return (
    <>
      {loading && <Loader/>}
      <ErrorHandling error={[errorTeacher]}/>

      <Select
        color={hasError ? 'failure' : ''}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        { (value === '') && <option value=''>Выберете учителя</option>}
        {teacherList.map((teacher) => (
          <option key={teacher.id} value={teacher.id}>
            {teacher.name}
          </option>
        ))}
      </Select>
    </>
  );
};
