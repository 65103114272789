import React, {useState} from 'react';
import {Button, Label, TextInput} from 'flowbite-react';
import {gql, useMutation} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../components/AuthContext';
import {ErrorHandling} from '../components/ErrorHandling';
import VersionAndDeveloper from '../components/VersionAndDeveloper';

const LOGIN = gql`
    mutation login($identifier: String!, $password: String!) {
        login(input: { identifier: $identifier, password: $password }) {
            jwt
            user {
                id
                role {
                    id
                    name
                    type
                    description
                }
                email
                blocked
                username
                confirmed
            }
        }
    }
`;

export default function LoginPage() {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const {setToken} = useAuth();
  const navigate = useNavigate();

  const [login, {loading, error}] = useMutation(LOGIN);

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let validation = true;

    if (name === 'identifier') {
      validation = /^[a-zA-Zа-яА-Я0-9._%+-]{1,63}$/.test(value);

      setIdentifier(value);
    } else if (name === 'password') {
      validation = /^[\s\S]{8,22}$/.test(value);

      setPassword(value);
    }

    setIsValid(validation);
  };

  // Функция для обработки отправки формы
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await login({variables: {identifier, password}});
      if (response.data.login.jwt) {
        await setToken(response.data.login.jwt);
        localStorage.setItem('token', response.data.login.jwt);
        console.log(response.data);
        navigate('/', {replace: true});
        window.location.reload();
      }
    } catch (e) {
      alert('Ошибка:' + e.toString());
      console.error(e);
    }
  };

  const renderError = () => {
    if (error) {
      return (
        <p className='text-red-500 mt-4'>Неправильный логин или пароль!</p>
      );
    }
    return null;
  };

  return (
    <>
      <ErrorHandling error={[error]}/>
      <div className='container mx-auto px-4 min-h-screen flex flex-col items-center justify-center'>
        <div className='mb-20'>
          <img src='/images/logo.svg' alt=''/>
        </div>
        <div className='text-5xl font-bold mb-10'>Вход</div>
        <form
          action='#'
          className='w-full max-w-[600px]'
          onSubmit={handleSubmit}
        >
          <div className='mb-8'>
            <div className='mb-2 block'>
              <Label value='Логин'/>
            </div>
            <TextInput
              sizing='lg'
              type='text'
              name='identifier'
              value={identifier}
              onChange={handleChange}
            />
          </div>
          <div className='mb-14'>
            <div className='mb-2 block'>
              <Label value='Пароль'/>
            </div>
            <TextInput
              sizing='lg'
              type='password'
              name='password'
              value={password}
              onChange={handleChange}
            />
            {renderError()}
          </div>
          <div className='flex justify-center'>
            <Button
              isProcessing={loading}
              disabled={loading || !isValid}
              onSubmit={handleSubmit}
              size='xl'
              className='w-full flex justify-center'
              type='submit'
            >
              Войти
            </Button>
          </div>
        </form>
      </div>
      <VersionAndDeveloper/>
    </>
  );
}
