import {gql, useMutation, useQuery} from '@apollo/client';
import AddTeacher from './AddTeacher';
import EditTeacher from './EditTeacher';
import React, {useEffect, useState} from 'react';
import {Button, Modal, Table} from 'flowbite-react';
import {MdAdd, MdClose} from 'react-icons/md';
import {RiDeleteBinLine, RiEdit2Line} from 'react-icons/ri';
import {HiOutlineExclamationCircle} from 'react-icons/hi';
import Loader from '../../../components/Loader';
import {ErrorHandling} from '../../../components/ErrorHandling';

const TEACHERS_LIST = gql`
    query teachers($filters: TeacherFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        teachers(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                attributes {
                    name
                }
                id
            }
        }
    }
`;

const DELETE_TEACHER = gql`
    mutation updateTeacher($data: TeacherInput!, $id: ID!) {
        updateTeacher(data: $data, id: $id) {
            data {
                attributes {
                    name
                }
            }
        }
    }
`;

export default function TeacherList() {
  const [showAdd, setShowAdd] = useState(false);
  const [editingTeacherId, setEditingTeacherId] = useState(null);
  const [editingTeacherName, setEditingTeacherName] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null);

  const {data, loading, error} = useQuery(TEACHERS_LIST, {
    variables: {
      filters: {
        institution: {
          id: {
            eq: localStorage.getItem('schoolID'),
          },
        },
      },
      pagination: {limit: 500},
      sort: ['name'],
    },
    skip: localStorage.getItem('schoolID') === '',
  });
  const [deleteTeacher, {error: errorDelete, loading: loadingDeleteTeacher}] = useMutation(DELETE_TEACHER);

  const getSortedTeachers = (teacherData) => {
    return teacherData?.slice().sort((a, b) => {
      const nameA = a.attributes.name.toUpperCase(); // для нечувствительности к регистру
      const nameB = b.attributes.name.toUpperCase();
      return nameA.localeCompare(nameB);
    });
  };
  const sortedTeachers = getSortedTeachers(data?.teachers?.data);

  useEffect(() => {
    if (data?.teachers?.data) {
      getSortedTeachers(data?.teachers?.data);
    }
  }, [data]);


  const updateList = () => {
    setShowAdd(false);
    setEditingTeacherId(null);
  };

  const startEdit = (teacher) => {
    if (teacher === null) {
      setEditingTeacherId(null);
      setEditingTeacherName('');
      return;
    }

    setEditingTeacherId(teacher.id);
    setEditingTeacherName(teacher.attributes.name);
  };

  const deletePerson = async (person) => {
    try {
      try {
        await deleteTeacher({
          variables: {
            id: person,
            data: {
              publishedAt: null,
            },
          },
        });
        updateList();
      } catch (error) {
        alert('Ошибка при удалении учителя:' + error.toString());
        console.error('Ошибка при удалении учителя', error);
      }
      setOpenDeleteModal(false);
    } catch (error) {
      alert('Ошибка при удалении класса:' + error.toString());
      console.error('Ошибка при удалении класса', error);
      setOpenDeleteModal(false);
    }
  };

  const openDeleteFunction = (id) => {
    setDeletedItemId(id);
    setOpenDeleteModal(true);
  };

  if (loading) return <Loader/>;
  if (error) return <p>Ошибка: {error.message}</p>;

  return (
    <>
      {loadingDeleteTeacher && <Loader/>}
      <ErrorHandling error={[error, errorDelete]}/>

      {!showAdd &&
        <Button className='mb-6' onClick={() => setShowAdd(true)}>
          Добавить нового учителя
          <MdAdd className='text-2xl ml-3'/>
        </Button>
      }
      {showAdd && (
        <div className='mb-6'>
          <AddTeacher cancel={() => setShowAdd(false)}/>
        </div>
      )}

      <Table striped>
        <Table.Head>
          <Table.HeadCell>Учитель</Table.HeadCell>
          <Table.HeadCell className=' text-right'></Table.HeadCell>
        </Table.Head>
        <Table.Body className='divide-y'>
          {sortedTeachers && sortedTeachers.map((teacherObj) => (
            <Table.Row key={teacherObj.id} className='bg-white dark:border-gray-700 dark:bg-gray-800'>
              <Table.Cell className='w-[calc=100% - 200px] text-xl text-black'>
                {editingTeacherId === teacherObj.id ? (
                  <EditTeacher
                    teacherId={editingTeacherId}
                    teacherName={editingTeacherName}
                    onClose={() => startEdit(null)}
                  />
                ) : (
                  <div className='font-medium'>
                    {teacherObj.attributes.name}
                  </div>
                )}
              </Table.Cell>
              <Table.Cell className='w-[200px]'>
                {editingTeacherId === teacherObj.id ? (
                  <MdClose className='cursor-pointer hover:text-blue-600 transition' title='Закрыть'
                    onClick={() => setEditingTeacherId(false)}/>
                ) : (
                  <div className='flex gap-4 justify-end'>
                    <RiEdit2Line
                      className='cursor-pointer hover:text-blue-600 transition text-xl'
                      title='Редактировать'
                      onClick={() => startEdit(teacherObj)}
                    />
                    <RiDeleteBinLine
                      className='cursor-pointer hover:text-red-600 transition text-xl'
                      title='Удалить'
                      onClick={() => openDeleteFunction(teacherObj.id)}
                    />
                    <Modal show={openDeleteModal} size='md'
                      onClose={() => setOpenDeleteModal(false)} popup>
                      <Modal.Header/>
                      <Modal.Body>
                        <div className='text-center'>
                          <HiOutlineExclamationCircle
                            className='mx-auto mb-4 h-14 w-14 text-red-500'/>
                          <div className='mb-5 text-lg font-normal'>
                            Вы точно хотите удалить ?
                          </div>
                          <div className='flex justify-center gap-4'>
                            <Button color='failure'
                              onClick={() => deletePerson(deletedItemId)}>
                              Удалить
                            </Button>
                            <Button color='gray' onClick={() => setOpenDeleteModal(false)}>
                              Отмена
                            </Button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
