import {useEffect, useMemo, useRef, useState} from 'react';
import {Accordion, Badge, Button, Modal} from 'flowbite-react';
import {CiCirclePlus} from 'react-icons/ci';
import {useMutation, useQuery} from '@apollo/client';
import {RiEdit2Line} from 'react-icons/ri';

import ScheduleCard from './ScheduleCard';
import formatTime from '../../utils/FormatTime';
import formatDateTime from '../../utils/FormatDateTime';
import AddNewSchedule from './AddNewSchedule';
import Loader from '../../components/Loader';
import EditScheduleData from './EditScheduleData';
import {ErrorHandling} from '../../components/ErrorHandling';
import {SelectClass} from './SelectClass';
import SelectInput from '../../components/SelectInput';
import {SCHEDULE_LIST, SCHEDULE_TIMES, SCHEDULES_NAME, UPDATE_SCHEDULE_NAME} from './qgl';


// Инициализация пустых дней недели
const emptyWeek = {
  'Monday': [],
  'Tuesday': [],
  'Wednesday': [],
  'Thursday': [],
  'Friday': [],
  'Saturday': [],
};

const dayTranslation = {
  'Monday': 'Понедельник',
  'Tuesday': 'Вторник',
  'Wednesday': 'Среда',
  'Thursday': 'Четверг',
  'Friday': 'Пятница',
  'Saturday': 'Суббота',
  'Sunday': 'Воскресенье',
};

function getCurrentDateFormatted() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // Месяцы начинаются с 0
  const day = now.getDate();

  // Добавляем ведущий ноль к месяцу и дню, если это необходимо
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${year}-${formattedMonth}-${formattedDay}`;
}

const dayOrder = {'Monday': 1, 'Tuesday': 2, 'Wednesday': 3, 'Thursday': 4, 'Friday': 5, 'Saturday': 6};

function compareClassNames(a, b) {
  // Разбиваем имя класса на числовую и буквенную части
  const matchA = a.match(/(\d+)([А-Яа-яA-Za-z])?/);
  const matchB = b.match(/(\d+)([А-Яа-яA-Za-z])?/);

  // Сравниваем сначала числа
  const numberA = parseInt(matchA[1], 10);
  const numberB = parseInt(matchB[1], 10);

  if (numberA < numberB) return -1;
  if (numberA > numberB) return 1;

  // Если числовые части одинаковы, сравнивscheduleIdаем буквы
  const letterA = matchA[2] || '';
  const letterB = matchB[2] || '';

  return letterA.localeCompare(letterB);
}

function compareByStartDate(a, b) {
  const dateA = new Date(a.attributes.start);
  const dateB = new Date(b.attributes.start);

  return dateA - dateB; // сортировка по возрастанию
}

const deepCopy = obj => JSON.parse(JSON.stringify(obj));

function processData(inputData, originalScheduleOrder) {
  const lessonsByDay = deepCopy(emptyWeek);

  inputData.forEach((schedule) => {
    const {subject, day, schedule_time, classroom, teacher, schedule_name} = schedule.attributes;
    const {id} = schedule;
    if (day === null || subject === null) return;

    if (!(day in dayOrder)) return;

    if (!lessonsByDay[day]) {
      lessonsByDay[day] = [];
    }

    const timeSlot = schedule_time.data?.attributes;
    const lesson = {
      id: id,
      time: `${formatTime(timeSlot?.timeStart)} - ${formatTime(timeSlot?.timeEnd)}`,
      scheduleTime: schedule_time.data?.attributes,
      scheduleTimeId: schedule_time.data?.id,
      subject: subject.data?.attributes?.name,
      subjectId: subject.data?.id,
      room: classroom.data?.attributes?.number,
      roomId: classroom.data?.id,
      teacher: teacher.data?.attributes?.name,
      teacherId: teacher.data?.id,
      schedule_name: schedule_name.data?.id,
    };
    lessonsByDay[day].push(lesson);
  });

  // Преобразование и сортировка данных
  return Object.keys(lessonsByDay)
    .sort((a, b) => dayOrder[a] - dayOrder[b])
    .map(day => ({
      day: dayTranslation[day],
      lessons: lessonsByDay[day].sort((a, b) => {
        const indexA = originalScheduleOrder.indexOf(a.id);
        const indexB = originalScheduleOrder.indexOf(b.id);
        return indexA - indexB;
      }),
    }));
}

export default function SchedulePage({schoolID}) {
  const [scheduleData, setScheduleData] = useState([]);
  const [scheduleTime, setScheduleTime] = useState([]);
  const [scheduleNameId, setScheduleNameId] = useState('');
  const [scheduleNameEditedData, setScheduleNameEditedData] = useState([]);
  const [selectedScheduleId, setSelectedScheduleId] = useState(null);
  const [addNewSchedule, setAddNewSchedule] = useState(false);
  const [originalScheduleOrder, setOriginalScheduleOrder] = useState([]);
  const [isLoadingRefetch, setIsLoadingRefetch] = useState(false);
  const [showEditScheduleSlot, setShowEditScheduleSlot] = useState(false);
  const [sortedClasses, setSortedClasses] = useState([]);
  const [typeSchool, setTypeSchool] = useState('high_school');
  const [selectedClassName, setSelectedClassName] = useState('');
  const [selectedTime, setSelectedTime] = useState('0');


  const scheduleTimeList = [
    {value: '0', label: 'Актуальные'},
    {value: '1', label: 'Архивные'},
    {value: '2', label: 'Предстоящие'},
  ];

  const {data, loading: loadingSchedules, error: errorSchedules, refetch: refetchSchedules} = useQuery(SCHEDULE_LIST, {
    variables: {
      filters: {
        institution: {id: {eq: schoolID}},
        schedule_name: {id: {eq: selectedScheduleId}},
      },
      pagination: { limit: 500 },
    },
    skip: schoolID === '' && selectedScheduleId !== null,
  });

  const {data: scheduleTimesData, error: errorScheduleTimes, loading: loadingScheduleTimesData} = useQuery(SCHEDULE_TIMES, {
    variables: {
      filters: {
        institution: {id: {eq: schoolID}},
        callGrid: {eq: typeSchool},
      },
      pagination: { limit: 2008 },
    },
    skip: schoolID === '' ,
  });

  function getCurrentFilterForScheduleNames() {
    const filter = {
      institution: {id: {eq: schoolID}},
    };

    if (selectedClassName !== '') {
      filter.class = {name: {eq: selectedClassName}};
    }

    switch (selectedTime) {
    case '0':
      filter.start = {lte: getCurrentDateFormatted()};
      filter.end = {gte: getCurrentDateFormatted()};
      break;
    case '1':
      filter.end = {lt: getCurrentDateFormatted()};
      break;
    case '2':
      filter.start = {gt: getCurrentDateFormatted()};
      break;
    default:
    }

    return filter;
  }

  const {
    data: scheduleNamesData,
    loading: loadingScheduleNamesData,
    error: errorSchedulesName,
  } = useQuery(SCHEDULES_NAME, {
    variables: {
      filters: getCurrentFilterForScheduleNames(),
      pagination: {limit: 2001},
      publicationState: 'PREVIEW',
    },
    skip: schoolID === '',
  });


  const [updateScheduleName, {error: errorScheduleNameUpdate, loading: loadingUpdateScheduleName}] = useMutation(UPDATE_SCHEDULE_NAME);

  const handleSelectClassNameChange = async (newSelectedClassName) => {
    await setSelectedClassName(newSelectedClassName);
  };

  const handleSelectTimeChange = async (newSelectedTime) => {
    await setSelectedTime(newSelectedTime);
  };

  useEffect(() => {
    if (data && data.schedules && Array.isArray(data.schedules.data)) {
      const sortedData = processData(data.schedules.data, originalScheduleOrder);
      setScheduleData(sortedData);
    }
  }, [data, originalScheduleOrder]);

  useEffect(() => {
    if (scheduleTimesData && scheduleTimesData.scheduleTimes) {
      const sortedScheduleTimes = [...scheduleTimesData.scheduleTimes.data]
        // сортируем по id
        .sort((a, b) => {
          return a.id - b.id;
        });

      setScheduleTime(sortedScheduleTimes);
    }
    // eslint-disable-next-line
  }, [data, scheduleTimesData]);

  useEffect(() => {
    if (selectedScheduleId !== null) {
      setIsLoadingRefetch(true);
      // Перезапрос данных с новым фильтром
      refetchSchedules().finally(() => {
        setIsLoadingRefetch(false);
      });
    }
    // eslint-disable-next-line
  }, [selectedScheduleId]);

  useEffect(() => {
    if (scheduleNamesData && scheduleNamesData.scheduleNames && Array.isArray(scheduleNamesData.scheduleNames.data)) {
      setOriginalScheduleOrder(scheduleNamesData.scheduleNames.data.map(item => item.id));
    }
  }, [scheduleNamesData]);

  const divRef = useRef(null);
  const [divWidth, setDivWidth] = useState(0);
  const [arrHeight, setArrHeight] = useState([]);

  // разделяем расписание звонков для будних дней и субботы
  function processDataByDay(scheduleData, dayFilter) {
    if (scheduleData) {
      return scheduleData.filter(schedule => schedule.attributes.day === dayFilter);
    }
  }

  const weekdaySchedule = useMemo(()=>processDataByDay(scheduleTime, 'Weekdays'), [scheduleTime]);
  const saturdaySchedule = useMemo(()=>processDataByDay(scheduleTime, 'Saturday'), [scheduleTime]);


  useEffect(() => {
    const arr = [];

    scheduleData.forEach((daySchedule, dayIndex)=> {
      const line =  Math.floor(dayIndex / divWidth + 1);

      (daySchedule.day === 'Суббота' ? saturdaySchedule : weekdaySchedule).forEach((time, timeIndex) => {
        const lesson = daySchedule.lessons.filter(lesson => lesson.scheduleTimeId === time.id);
        if (arr[line] === undefined) {
          arr[line] = [];
        }

        if (arr[line][timeIndex]  === undefined) {
          arr[line][timeIndex] = lesson.length;
        } else {
          arr[line][timeIndex] = Math.max(arr[line][timeIndex], lesson.length);
        }
      });
    });

    setArrHeight(arr);
  },
  [divWidth, scheduleData, saturdaySchedule,  weekdaySchedule]);

  useEffect(() => {
    const updateWidth = () => {
      setTimeout(() => {
        if (divRef.current) {
          setDivWidth(Math.round(divRef.current.offsetWidth / 530));
        }
      }, 1); // Задержка в 1 мс может быть достаточной
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  function handleButtonPublish(id, publishStatus, event) {
    event.stopPropagation();
    // Находим расписание, которое хотим обновить
    const scheduleToUpdate = scheduleNamesData?.scheduleNames?.data.find(item => item.id === id);
    if (!scheduleToUpdate) {
      console.error('Расписание не найдено');
      return;
    }

    const newPublishStatus = publishStatus ? null : new Date();

    try {
      // Вызываем мутацию с новыми данными
      updateScheduleName({
        variables: {
          id: id,
          data: {
            institution: localStorage.getItem('schoolID'),
            publishedAt: newPublishStatus,
          },
        },
        refetchQueries: ['scheduleNames', 'schedules', 'scheduleTimes'],
      }).then(() => {
        console.log('Статус расписания обновлен');
        setIsLoadingRefetch(true);
        refetchSchedules().then(() => setIsLoadingRefetch(false));
      });
    } catch (error) {
      alert('Ошибка при обновлении статуса расписания:' + error.toString());
      console.error('Ошибка при обновлении статуса расписания', error);
    }
  }

  function editSchedule(id, data, event) {
    event.stopPropagation();
    setShowEditScheduleSlot(true);
    setScheduleNameId(id);
    setScheduleNameEditedData(data);
  }

  useEffect(() => {
    setSortedClasses([...(scheduleNamesData?.scheduleNames?.data || [])].sort((a, b) => {
      const classCompareResult = compareClassNames(
        a.attributes.class.data?.attributes?.name + a.attributes.class.data?.attributes?.letter,
        b.attributes.class.data?.attributes?.name + b.attributes.class.data?.attributes?.letter,
      );

      if (classCompareResult !== 0) {
        return classCompareResult;
      }

      return compareByStartDate(a, b);
    }));
  }, [scheduleNamesData]);

  async function ChangeSelectedSchedule(scheduleID, classNumber) {
    await setScheduleData([]);
    await setSelectedScheduleId(null);
    await setSelectedScheduleId(scheduleID);
    await setTypeSchool(+classNumber > 4 ? 'high_school' : 'low_school');
  }

  if (errorSchedules) return <>Ошибка: {errorSchedules.message}</>;

  return (
    <div className='mt-0'>
      <ErrorHandling
        error={[errorSchedules, errorScheduleTimes, errorSchedulesName, errorScheduleNameUpdate]}/>
      {(loadingScheduleTimesData || loadingUpdateScheduleName || isLoadingRefetch || loadingSchedules || loadingScheduleNamesData) && <Loader/>}

      {addNewSchedule && (
        <div className='my-6'>
          <AddNewSchedule onClose={() => setAddNewSchedule(false)}/>
        </div>
      )}

      {!addNewSchedule && (
        <>
          <Button
            onClick={() => setAddNewSchedule(true)}
            className='mb-6'
            color='blue'
          >
            Добавить новое расписание <CiCirclePlus className='w-8 h-8 ml-4'/>
          </Button>
          <div className='flex items-center' ref={divRef}>
            <div className='flex gap-4 mb-8 mr-4'>
              <SelectClass
                onChange={handleSelectClassNameChange}
                inputTitle='Параллель'
              />
            </div>
            <div className='flex gap-4 mb-8 mr-4'>
              <SelectInput
                onChange={(e) => handleSelectTimeChange(e.target.value)}
                options={scheduleTimeList}
                inputTitle='Расписания'
              />
            </div>
          </div>
          {sortedClasses && (
            <Accordion collapseAll>
              {
                sortedClasses.map(item => (
                  <Accordion.Panel key={item.id}>
                    <Accordion.Title className='accordion-title'>
                      <div className='flex items-center justify-between w-full p-5'
                        onClick={() => ChangeSelectedSchedule(item.id, item.attributes.class.data?.attributes?.name)}>
                        <div className='text inline-flex items-center'>
                          <span className='text-xl font-bold'>
                            {item.attributes.class.data?.attributes?.name}-{item.attributes.class.data?.attributes?.letter} класс</span>.
                          <span className='ml-4'></span>Расписание с <span
                            className='text-xl ml-3'>{formatDateTime(item.attributes.start, false)}</span>
                          <span className='mx-2'>по</span> <span
                            className='text-xl'>{formatDateTime(item.attributes.end, false)}
                          </span>
                          <RiEdit2Line
                            className='cursor-pointer hover:text-blue-600 transition text-xl inline-block ml-4'
                            title='Редактировать'
                            onClick={(e) => editSchedule(item.id, item, e)}
                          />
                          <div onClick={e => e.stopPropagation()}>
                            <Modal show={showEditScheduleSlot} size='4xl'
                              onClose={() => setShowEditScheduleSlot(false)} popup>
                              <Modal.Header/>
                              <Modal.Body>
                                <EditScheduleData data={scheduleNameEditedData}
                                  scheduleId={scheduleNameId}
                                  onClose={() => setShowEditScheduleSlot(false)}/>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </div>
                        <div className='flex gap-4'>
                          <div
                            className='cursor-pointer text-sm underline hover:no-underline hover:text-cyan-600'
                            onClick={(e) =>
                              handleButtonPublish(
                                item.id,
                                item.attributes.publishedAt,
                                e,
                              )
                            }
                          >
                            {item.attributes.publishedAt? <Badge color='success'>Активно</Badge> : <Badge color='gray'>Скрыто</Badge>}
                          </div>
                        </div>
                      </div>
                    </Accordion.Title>
                    <Accordion.Content>
                      <div className='flex flex-wrap w-full gap-8'>
                        <ScheduleCard
                          divWidth={divWidth}
                          arrHeight={arrHeight}
                          data={scheduleData}
                          scheduleName={item.id}
                          scheduleTimes={weekdaySchedule}
                          scheduleSaturdayTimes={saturdaySchedule}
                          loading={isLoadingRefetch}
                        />
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                ),
                )
              }
            </Accordion>
          )}
          {
            sortedClasses.length === 0 && !isLoadingRefetch && !loadingSchedules && !loadingScheduleNamesData ?
              <p className='p-4 text-center text-xl'>Расписания нет.</p> : <></>
          }
        </>
      )}
    </div>
  );
}
