import {Button, Datepicker} from 'flowbite-react';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {ErrorHandling} from '../../components/ErrorHandling';
import Loader from '../../components/Loader';

const UPDATE_SCHEDULE_NAME = gql`
    mutation updateScheduleName($data: ScheduleNameInput!, $id: ID!) {
        updateScheduleName(data: $data, id: $id) {
            data {
                attributes {
                    start
                    end
                }
                id
            }
        }
    }
`;
const CLASSES_LIST = gql`
    query classes($filters: ClassFiltersInput, $pagination: PaginationArg = {} $sort: [String] = []) {
        classes(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                attributes {
                    name
                    letter
                }
                id
            }
        }
    }
`;
export default function EditScheduleData({data, onClose, scheduleId}) {

  const [updateScheduleName, {loading: loadingUpdateScheduleName}] = useMutation(UPDATE_SCHEDULE_NAME);

  const [startDate, setStartDate] = useState(new Date(data.attributes.start));
  const [endDate, setEndDate] = useState(new Date(data.attributes.end));
  const [selectedClassId, setSelectedClassId] = useState(data.attributes.class.data.id);
  const {data: classData, loading, error} = useQuery(CLASSES_LIST, {
    variables: {
      filters: {
        institution: {
          id: {
            eq: localStorage.getItem('schoolID'),
          },
        },
      },
      sort: ['name'],
      pagination: {
        limit: 300,
      },
    },
    skip: localStorage.getItem('schoolID') === '',
  });

  const handleSubmit = async () => {
    try {
      await updateScheduleName({
        variables: {
          id: scheduleId,
          data: {
            start: toLocalIsoString(startDate),
            end: toLocalIsoString(endDate),
            class: selectedClassId,
          },
        },
        refetchQueries: ['scheduleNames', 'schedules', 'scheduleTimes'],
      });
      onClose(); // Закрытие модального окна
    } catch (error) {
      alert('Ошибка обновления расписания:' + error.toString());
      console.error('Ошибка обновления расписания:', error);
    }
  };

  function formatDate(date) {
    return date.toLocaleDateString('ru-RU', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }

  function toLocalIsoString(date) {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split('T')[0];
  }

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    const selectedEndDate = new Date(date);
    if (selectedEndDate >= startDate) {
      setEndDate(selectedEndDate);
    } else {
      alert('Дата окончания не может быть раньше даты начала');
    }
  };
  useEffect(() => {
    if (classData) {
      const currentClass = [...classData.classes.data].find(cls =>
        cls.attributes.name === data.attributes.class.data.attributes.name &&
        cls.attributes.letter === data.attributes.class.data.attributes.letter,
      );

      if (currentClass) {
        setSelectedClassId(currentClass.id);
      }
    }
    if (data) {
      setStartDate(new Date(data.attributes.start)); // Инициализируем объектами Date
      setEndDate(new Date(data.attributes.end));
    }
  }, [classData, data]);

  if (loading) return <Loader/>;
  if (error) return <p>Ошибка :(</p>;

  return (
    <>
      {(loadingUpdateScheduleName || loading) && <Loader/>}
      <ErrorHandling error={[error]}/>
      <div className='min-h-[500px]'>
        <div className='flex justify-between gap-4'>
          <div className='w-[47%] mb-8'>
            Начало
            <Datepicker
              labelClearButton='Отмена'
              showTodayButton={false}
              defaultDate={new Date(data.attributes.start)}
              value={String(formatDate(startDate))}
              onSelectedDateChanged={handleStartDateChange}
              language='ru-RU'
            />
          </div>
          <div className='w-[47%]'>
            Конец
            <Datepicker
              labelClearButton='Отмена'
              value={String(formatDate(endDate))}
              showTodayButton={false}
              defaultDate={new Date(data.attributes.end)}
              onSelectedDateChanged={handleEndDateChange}
              language='ru-RU'
            />
          </div>
        </div>
        <div className='flex gap-6 mt-6'>
          <Button color='gray' onClick={onClose}>Отмена</Button>
          <Button
            type='submit'
            onClick={handleSubmit}
            disabled={!startDate || !endDate || endDate < startDate}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </>
  );
}
