import {Select} from 'flowbite-react';
import React, {useEffect, useState} from 'react';
import {useQuery, gql} from '@apollo/client';

import {ErrorHandling} from '../../components/ErrorHandling';
import Loader from '../../components/Loader';

const CLASSROOMS_LIST = gql`
    query classrooms($filters: ClassroomFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        classrooms(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                attributes{
                    name
                    number
                }
                id
            }
        }
    }
`;

export const SelectClassRoom = ({
  hasError,
  value,
  onChange,
}) => {
  const [classroomList, setClassroomList] = useState([]);

  const {data: classroomResult, error: errorClassroom, loading} = useQuery(CLASSROOMS_LIST, {
    variables: {
      filters: {
        institution: {id: {eq: localStorage.getItem('schoolID')}},
      },
      sort: ['number'],
      pagination: {
        limit: 500,
      },
    },
    skip: localStorage.getItem('schoolID') === '',
  });

  useEffect(() => {
    if (!classroomResult?.classrooms?.data) {
      return;
    }

    const res = [];

    classroomResult.classrooms.data.slice().sort((a, b) => {
      let nameA = Number(a.attributes?.number);
      let nameB = Number(b.attributes?.number);

      if (nameA > nameB) {
        return 1;
      }

      if (nameA < nameB) {
        return -1;
      }

      nameA = a.attributes?.number.toUpperCase(); // для нечувствительности к регистру
      nameB = b.attributes?.number.toUpperCase();

      return nameA.localeCompare(nameB);
    }).forEach((item) => {
      const name = item.attributes?.number + ' - ' + item.attributes?.name;
      res.push({
        id: item.id,
        name: name.trim(),
      }
      );
    });

    setClassroomList(res);
  }, [classroomResult]);

  return (
    <>
      {loading && <Loader/>}
      <ErrorHandling error={[errorClassroom]}/>

      <Select
        color={hasError ? 'failure' : ''}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        { (value === '') && <option value=''>Выберете класс</option>}
        {classroomList.map((classroomObj) => (
          <option key={classroomObj.id} value={classroomObj.id}>
            {classroomObj.name}
          </option>
        ))}
      </Select>
    </>
  );
};
