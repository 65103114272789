import {gql, useMutation, useQuery} from '@apollo/client';
import React, {useMemo, useState} from 'react';
import {Button, Modal, Table} from 'flowbite-react';
import {MdAdd, MdClose} from 'react-icons/md';
import {RiDeleteBinLine, RiEdit2Line} from 'react-icons/ri';
import {HiOutlineExclamationCircle} from 'react-icons/hi';

import AddClassroom from './AddClassroom';
import EditClassRoomsData from './EditClassRoomsData';
import Loader from '../../../components/Loader';
import {ErrorHandling} from '../../../components/ErrorHandling';

const CLASSROOMS_LIST = gql`
    query classrooms($filters: ClassroomFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        classrooms(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                attributes {
                    name
                    number
                }
                id
            }
        }
    }
`;

const DELETE_CLASSROOM = gql`
    mutation updateClassroom($data: ClassroomInput!, $id: ID!) {
        updateClassroom(data: $data, id: $id) {
            data {
                attributes {
                    name
                    number
                }
            }
        }
    }
`;

const getSortedClassrooms = (list) => {
  return list?.slice().sort((a, b) => {
    let nameA = Number(a.attributes.number);
    let nameB = Number(b.attributes.number);

    if (nameA > nameB) {
      return 1;
    }

    if (nameA < nameB) {
      return -1;
    }

    nameA = a.attributes.number.toUpperCase(); // для нечувствительности к регистру
    nameB = b.attributes.number.toUpperCase();

    return nameA.localeCompare(nameB);
  });
};

export default function ClassroomList() {
  const [showAdd, setShowAdd] = useState(false);
  const [editClassRoomId, setEditClassRoomId] = useState(null);
  const [editName, setEditName] = useState('');
  const [editNumber, setEditNumber] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null);

  const {data, loading: loadingList, error} = useQuery(CLASSROOMS_LIST, {
    variables: {
      filters: {
        institution: {
          id: {
            eq: localStorage.getItem('schoolID'),
          },
        },
      },
      sort: ['number'],
      pagination: {limit: 500},
    },
    skip: localStorage.getItem('schoolID') === '',
  });
  const [deleteClassroom, {loading: LoadingDelete}] = useMutation(DELETE_CLASSROOM);

  const sortedClassrooms = useMemo(() => {
    if (!data?.classrooms?.data) {
      return [];
    }

    return getSortedClassrooms(data?.classrooms?.data);
  }, [data]);

  const updateList = async () => {
    setShowAdd(false);
    setEditClassRoomId(null);
  };

  const startEdit = (classroom) => {
    setEditClassRoomId(classroom.id);
    setEditName(classroom.attributes.name);
    setEditNumber(classroom.attributes.number);
  };

  const deleteRoom = async (classroom) => {
    try {
      await deleteClassroom({
        variables: {
          id: classroom,
          data: {
            publishedAt: null,
          },
        },
        refetchQueries: ['classrooms'],
      });
      await updateList();
      setOpenDeleteModal(false);
    } catch (error) {
      alert('Ошибка при удалении кабинета:' + error.toString());
      console.error('Ошибка при удалении кабинета', error);
      setOpenDeleteModal(false);
    }
  };

  const openDeleteFunction = (id) => {
    setDeletedItemId(id);
    setOpenDeleteModal(true);
  };

  if (loadingList) return <Loader/>;

  return (
    <>
      {LoadingDelete && (<Loader/>)}
      <ErrorHandling error={[error]}/>
      {!showAdd &&
        <Button className='mb-6' onClick={() => setShowAdd(true)}>
          Добавить новый кабинет
          <MdAdd className='text-2xl ml-3'/>
        </Button>
      }
      {showAdd && (
        <div className='mb-6'>
          <AddClassroom refetch={updateList} cancel={() => setShowAdd(false)}/>
        </div>
      )}
      <Table striped>
        <Table.Head>
          <Table.HeadCell>Номер кабинета</Table.HeadCell>
          <Table.HeadCell>Название кабинета</Table.HeadCell>
          <Table.HeadCell className='text-right'></Table.HeadCell>
        </Table.Head>
        <Table.Body className='divide-y'>
          {sortedClassrooms && sortedClassrooms.map((classroomObj) => (
            <Table.Row key={classroomObj.id}
              className='bg-white dark:border-gray-700 dark:bg-gray-800 relative'>
              <Table.Cell className='text-xl text-black'>
                {editClassRoomId === classroomObj.id ? (
                  <>
                    <EditClassRoomsData
                      editClassRoomId={editClassRoomId}
                      name={editName}
                      number={editNumber}
                      onSave={()=>{setEditClassRoomId(null);}}
                    />
                  </>
                ) : (
                  <div className='font-medium'>
                    {classroomObj.attributes.number}
                  </div>
                )}
              </Table.Cell>
              <Table.Cell className='text-xl text-black'>
                {editClassRoomId !== classroomObj.id &&
                  <div className='font-medium'>
                    {classroomObj.attributes.name}
                  </div>}
              </Table.Cell>
              <Table.Cell className='w-[200px]'>
                {editClassRoomId === classroomObj.id ? (
                  <MdClose className='cursor-pointer hover:text-blue-600 transition' title='Закрыть'
                    onClick={() => setEditClassRoomId(false)}/>
                ) : (
                  <div className='flex gap-4 justify-end'>
                    <RiEdit2Line
                      className='cursor-pointer hover:text-blue-600 transition text-xl'
                      title='Редактировать'
                      onClick={() => startEdit(classroomObj)}
                    />
                    <RiDeleteBinLine
                      className='cursor-pointer hover:text-red-600 transition text-xl'
                      title='Удалить'
                      onClick={() => openDeleteFunction(classroomObj.id)}/>
                    <Modal show={openDeleteModal} size='md'
                      onClose={() => setOpenDeleteModal(false)} popup>
                      <Modal.Header/>
                      <Modal.Body>
                        <div className='text-center'>
                          <HiOutlineExclamationCircle
                            className='mx-auto mb-4 h-14 w-14 text-red-500'/>
                          <div className='mb-5 text-lg font-normal'>
                            Вы точно хотите удалить ?
                          </div>
                          <div className='flex justify-center gap-4'>
                            <Button color='failure'
                              onClick={() => deleteRoom(deletedItemId)}>
                              Удалить
                            </Button>
                            <Button color='gray' onClick={() => setOpenDeleteModal(false)}>
                              Отмена
                            </Button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
